<template>
  <div class="view-container">
    <div class="card">
      <div class="card-header card-header-divider">
        <div class="card-title">Настройка напоминаний о прохождении теста</div>
      </div>
      <div class="card-body">
        <form
            class="notification-form"
            @submit.prevent="submitNotificationOptions"
        >
          <div class="flex flex-column m-auto notif-options__checkboxes">
            <custom-checkbox
                v-model="form.notifyEmployee3Days"
                :checked="form.notifyEmployee3Days"
                :label="'Отправить оповещение сотруднику, если он не прошел тест в течение трех дней'"
            />
            <custom-checkbox
                v-model="form.notifyEmployee7Days"
                :checked="form.notifyEmployee7Days"
                :label="'Отправить оповещение сотруднику, если он не прошел тест в течение недели'"
            />
            <custom-checkbox
                v-model="form.notifyAdmin3Days"
                :checked="form.notifyAdmin3Days"
                :label="'Отправить оповещение мне, если сотрудник не прошел тест в течение трех дней'"
            />
            <custom-checkbox
                v-model="form.notifyAdmin7Days"
                :checked="form.notifyAdmin7Days"
                :label="'Отправить оповещение мне, если сотрудник не прошел тест в течение недели'"
            />
          </div>
          <div class="button-wrapper">
            <button type="submit" class="btn btn-accent mr-10">Сохранить</button>
          </div>
          <div class="form-preloader" v-if="isPreloader">
            <preloader/>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationsOptionsForm from "@/components/Forms/Notifications/NotificationsOptionsForm.vue";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";
import {reactive, ref} from "@vue/reactivity";
import {useStore} from "vuex";
import {computed, onBeforeMount} from "@vue/runtime-core";
import Preloader from "@/components/Technical/Preloader.vue";

export default {
  name: "NotificationTests",
  components: {Preloader, CustomCheckbox, NotificationsOptionsForm},
  setup() {
    const store = useStore();
    const isPreloader = ref(false);
    let optionsList = computed(() => store.state.notifications.optionsList);
    let form = reactive({
      notifyEmployee3Days: false,
      notifyEmployee7Days: false,
      notifyAdmin3Days: false,
      notifyAdmin7Days: false,
    });

    const submitNotificationOptions = () => {
      isPreloader.value = true;
      store
          .dispatch("notifications/saveOptionsNotification", form).then(() => isPreloader.value = false);
    };

    onBeforeMount(async () => {
      isPreloader.value = true;
      await store.dispatch("notifications/getOptionsLabels");
      await store.dispatch("notifications/getOptionsList")
      for (let option in optionsList.value) form[option] = optionsList.value[option]
      isPreloader.value = false;
    });

    return {
      submitNotificationOptions,
      form,
      isPreloader,
    }
  },
}
</script>

<style scoped lang="scss">
.notification-form {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.button-wrapper {
  align-self: flex-end;
}

</style>